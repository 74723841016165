import { BaseRecord, ForeignKey } from 'types/tables/base';
import { Tickler } from 'types/grdn';
import { NextStep } from 'components/features/CarePlan/CarePlanTypes';

export type PatientId = string;

export enum LoadingState {
  Loading = 'Loading',
  PartialFailure = 'PartialFailure',
  Success = 'Success',
  Failure = 'Failure',
}

export interface PatientLocation {
  state: string;
  valid: boolean;
}

export enum VisitModality {
  Async = 'Async',
  Video = 'Video',
  Phone = 'Phone',
}

export interface Patient extends BaseRecord {
  id: PatientId;
  mrn: number;
  secondaryMrns: number[];
  sponsorId: ForeignKey;
  sendbirdUserId: ForeignKey;
  firstName: string | null;
  lastName: string | null;
  preferredName?: string;
  assigned: ForeignKey[];
  location: PatientLocation;
  careteamId?: ForeignKey;
  carePodId: ForeignKey;
  ticklers?: Tickler[];
  ticklerLoadingState?: LoadingState;
  ticklersFetchedAt?: string;
  activeNextSteps?: NextStep[];
  completedNextSteps?: NextStep[];
  nextStepsLoadingState?: LoadingState;
  nextStepsFetchedAt?: string;
  athenaStatus: string;
  copilotSuggestion?: string;
  copilotLoadingState?: LoadingState;
  copilotCanRegenerate?: boolean;
  copilotError?: string;
  copilotMaximized?: boolean;
  isStartingDigitalVisit?: boolean;
  isCheckingOutDigitalVisit?: boolean;
  isGettingDigitalVisit?: boolean;
  visitModality?: VisitModality;
  appointment?: {
    departmentId: string;
    date: string;
    copay: number;
    providerId: string;
    startTime: string;
    duration: string;
    appointmentTypeId: string;
    appointmentType: string;
    appointmentId: string;
    patientAppointmentTypeName: string;
    appointmentStatus: string;
    appointmentcopay: {
      collectedforother: number;
      collectedforappointment: number;
      insurancecopay: number;
    };
  };
}

export enum PatientLoadState {
  NotLoaded = 'NotLoaded',
  Switching = 'Switching',
  Loading = 'Loading',
  Loaded = 'Loaded',
  NotFound = 'NotFound',
  Error = 'Error',
}
