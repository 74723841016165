import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Header, Menu, Popup } from 'semantic-ui-react';
import { debounce, isBoolean, truncate } from 'lodash';
import { useSelector } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toast } from 'react-toastify';
import css from './Composer.module.css';
import SendButton from './SendButton';
import RequestToScheduleVisitButton from './RequestToScheduleVisitButton';
import StatusDropdown from './StatusDropdown';
import AttachButtonDeprecated from './AttachButton';
import VideoButton from './VideoButton';
import {
  ErrorPill,
  LicensingPill,
  CareteamPill,
  FaceToFacePill,
  CloseKnitPill,
} from './ComposerPills';
import ChatCopilot from 'components/features/Composer/ChatCopilot';
import {
  getCopilotSuggestion,
  getPatientCopilotLoadingState,
  getPatientCopilotSuggestion,
  getPatientCopilotCanRegenerate,
  getPatientCopilotError,
  removeCopilotSuggestion,
  getPatientCopilotMaximized,
  setCopilotMaximizedState,
} from 'reducers/patients';
import { macroSelectors } from 'reducers/macros';
import { Progress } from 'components/ui/Progress';
import { getPermissions, getUser } from 'reducers/user';
import { TypingIndicator } from 'components/ui/TypingIndicator';
import { Macro } from 'types/tables/macros';
import { createPendingMessage } from 'lib/util';
import {
  useActiveChat,
  useActivePatient,
  useAppDispatch,
  useParameterizedSelector,
  useSendTextMessage,
} from 'lib/hooks';
import KEYS from 'lib/keys';
import {
  getPatientHasHadVideoVisit,
  getPatientProfile,
  getPatientProfileId,
} from 'selectors/profiles';
import { fetchHasHadVideoVisits } from 'reducers/profiles';
import { getIsCloseKnitProvider, getProviderByGoogleId } from 'reducers/providers';
import { getCareteamRecord } from 'reducers/careteams';
import { PillLoader, ProfilePicture } from 'components/ui/Pills';
import { StateKey } from 'types/geo';
import { getChannelMessageDraft } from 'reducers/channels';
import { getPatientVisits } from 'reducers/visits';
import {
  INSERT_MESSAGE_DRAFT,
  REMOVE_MESSAGE_DRAFT,
  UPDATE_MESSAGE_DRAFT,
} from 'legacy/actions/db';
import { Channel, ChannelType } from 'types/tables/channels';
import { profileIsError } from 'types/tables/profiles';
import { getIsCloseKnitPatient } from 'reducers/providerGroups';
import formCss from 'components/design-system/Form.module.css';
import {
  getPatientCareteamId,
  getPatientCurrentState,
  isPatientDeactivated,
} from 'reducers/patients';
import * as GrdnApi from 'lib/grdn';
import { EventGroupEnum, AiEventName } from 'types/grdn';
import { Permission, ProviderRole } from 'types/tables/providers';

const MACRO_LEADER = '/';
const MACRO_CHAR_LIMIT = 200;
const MACRO_DISPLAY_LIMIT = 0; // no limit for now b/c scroll

const styles = {
  container: {
    padding: '0 10px 10px 10px',
    width: 'inherit',
    textAlign: 'right',
  },
  progress: {
    margin: '0 0 .5em 0',
  },
  hiddenProgress: {
    visibility: 'hidden',
    margin: 0,
  } as React.CSSProperties,
  attachBtn: {},
  sendBtn: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginRight: 0,
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  btnActive: {
    backgroundColor: 'var(--grey-20)',
  },
};

enum FocusableComponents {
  textArea = 'textArea',
  sendButton = 'sendButton',
}

enum LoadingState {
  Loading = 'loading',
  Success = 'success',
  Failure = 'failure',
  NotStarted = 'not started',
}

export default function Composer() {
  const { channel, sbChannel } = useActiveChat();
  const dispatch = useAppDispatch();
  const isPatientChat = channel.channelType === ChannelType.Primary;
  const patient = useActivePatient();
  const messageDraft = useParameterizedSelector(getChannelMessageDraft, channel.id);
  const macros = useSelector(macroSelectors.selectAll);
  const patientProfile = useParameterizedSelector(getPatientProfile, patient.id);
  const patientLocation = useParameterizedSelector(getPatientCurrentState, patient.id);
  const isDeactivated = useParameterizedSelector(isPatientDeactivated, patient.id);
  const isPatientChatAndDisabled = isDeactivated && isPatientChat;
  const user = useSelector(getUser);
  const patientHasHadVideoVisit = useParameterizedSelector(getPatientHasHadVideoVisit, patient.id);
  const patientProfileId = useParameterizedSelector(getPatientProfileId, patient.id);
  const visits = useParameterizedSelector(getPatientVisits, patient.id);
  const myProvider = useParameterizedSelector(getProviderByGoogleId, user.googleId);
  const sendTextMessage = useSendTextMessage(patient);
  const [chatInput, setChatInput] = useState(messageDraft);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedMacro, setSelectedMacro] = useState(-1);
  const [showMacros, setShowMacros] = useState(false);
  const [macroFilter, setMacroFilter] = useState('');
  const [isFocused, setIsFocused] = useState({ textArea: false, sendButton: false });
  const [isPatientProfileLoading, setIsPatientProfileLoading] = useState(true);
  const [hasVideoVisitsLoadingState, setHasVideoVisitsLoadingState] = useState(
    LoadingState.NotStarted,
  );
  const { chatCopilot } = useFlags();
  const permissions = useSelector(getPermissions);

  const textAreaElement = useRef<HTMLTextAreaElement>(null);

  let visibleMacros: Macro[] = [];

  const setShowMacrosWithPermission = (showMacros) => {
    permissions?.includes(Permission.MACRO_READ) && setShowMacros(showMacros);
  };

  useEffect(() => {
    setChatInput(messageDraft);
    setHasVideoVisitsLoadingState(LoadingState.NotStarted);
  }, [dispatch, messageDraft, patient.id]);

  const isPatientProfileError = profileIsError(patientProfile);

  useEffect(() => {
    if (isPatientChat && patientProfileId && !isBoolean(patientHasHadVideoVisit)) {
      setHasVideoVisitsLoadingState(LoadingState.Loading);
      dispatch(fetchHasHadVideoVisits(patient.id))
        .then(() => {
          setHasVideoVisitsLoadingState(LoadingState.Success);
          return;
        })
        .catch(() => {
          setHasVideoVisitsLoadingState(LoadingState.Failure);
        });
    }
  }, [dispatch, patient.id, patientProfileId, isPatientChat, patientHasHadVideoVisit]);

  useEffect(() => {
    if (isPatientProfileError || patientProfileId) setIsPatientProfileLoading(false);
  }, [isPatientProfileError, patientProfileId]);

  const providerCareteamId = myProvider?.careteamId || '';
  const patientCareteamId = useParameterizedSelector(getPatientCareteamId, patient.id);

  const providerCareteam = useParameterizedSelector(getCareteamRecord, providerCareteamId);
  const patientSponsorId = patient.sponsorId ? patient.sponsorId : '';
  const isCloseKnitPatient = useParameterizedSelector(getIsCloseKnitPatient, patientSponsorId);
  const isCloseKnitProvider = useParameterizedSelector(
    getIsCloseKnitProvider,
    myProvider?.id || '',
  );

  // TODO: This should be pulled into a helper lib
  const processMacro = (text: string) => {
    const fname = profileIsError(patientProfile)
      ? ''
      : patientProfile.preferredName?.replace('"', '') || patientProfile.firstName;
    const lname = profileIsError(patientProfile) ? '' : patientProfile.lastName;
    // Be very careful changing variable names otherwise existing macros will break
    return text
      .replace(/\$firstName/g, fname)
      .replace(/\$lastName/g, lname)
      .replace(/\$clinicianFirstName/g, user.givenName)
      .replace(/\$clinicianFullName/g, myProvider?.displayName || user.name)
      .replace(/\$clinician/g, myProvider?.displayName || user.name)
      .replace(/\$provider/g, myProvider?.displayName || user.name);
  };

  const updateIsUploading = useCallback((newVal: boolean) => {
    setIsUploading(newVal);
  }, []);

  const startTypingDebounce = useRef(
    debounce(sbChannel.startTyping, 300, { leading: true, trailing: false }),
  );

  useEffect(() => {
    startTypingDebounce.current = debounce(sbChannel.startTyping, 300, {
      leading: true,
      trailing: false,
    });
  }, [sbChannel]);

  useEffect(() => {
    return startTypingDebounce.current.cancel();
  }, []);

  const handleTypingStatus = (chatInput: string) => {
    if (chatInput === '') {
      startTypingDebounce.current.cancel();
      sbChannel.stopTyping();
    } else {
      startTypingDebounce.current();
    }
  };

  const handleChatInputChange = (e) => {
    const chatInput = e.target.value;
    if (chatInput && !messageDraft) {
      // insert a placeholder draft in order to trigger sidebar draft
      // icon as soon as chat input is populated
      dispatch(INSERT_MESSAGE_DRAFT(channel.id, chatInput));
    }
    if (!chatInput && messageDraft) {
      // remove placeholder draft in order to trigger sidebar draft
      // icon removal as soon as chat input is deleted
      dispatch(REMOVE_MESSAGE_DRAFT(channel.id));
    }

    // '/' should trigger the macro menu as well
    const newShowMacros = chatInput.startsWith(MACRO_LEADER);
    const macroFilter = chatInput.substring(1) ?? '';
    setMacroFilter(macroFilter);
    setChatInput(chatInput);
    setShowMacrosWithPermission(newShowMacros);
    handleTypingStatus(chatInput);
  };

  const handleSendButtonClick = async () => {
    const cleanInput = chatInput.trim();

    if (cleanInput && myProvider) {
      if (myProvider.role === ProviderRole.INACTIVE) {
        toast.error('You do not have permission to access this feature.');
      } else {
        const pendingMessage = createPendingMessage(myProvider, cleanInput);
        sendTextMessage(pendingMessage);
        sbChannel.stopTyping();
        setChatInput('');
        setIsFocused({ sendButton: false, textArea: false });

        // Clear message draft.
        dispatch(REMOVE_MESSAGE_DRAFT(channel.id));

        // Clear copilot suggestion
        removeCopilotSuggestion(patient.id, dispatch);
      }
    }
  };

  const handleOnFocus = (component: FocusableComponents) => {
    setIsFocused({ ...isFocused, [component]: true });
    if (component === FocusableComponents.textArea && !copilotSuggestion) {
      GrdnApi.createEvent({
        eventGroup: EventGroupEnum.chat,
        eventName: AiEventName.clickToGenerateSuggestion,
        eventData: { patientId: patient.id },
      });
      generateCopilotSuggestion();
    }
  };

  const handleOnBlur = (component: FocusableComponents) => {
    setIsFocused({ ...isFocused, [component]: false });
  };

  const handleTextAreaBlur = () => {
    handleOnBlur(FocusableComponents.textArea);
    if (messageDraft && chatInput) {
      dispatch(UPDATE_MESSAGE_DRAFT(channel.id, chatInput));
    }
    // don't auto close for mobile width
    if (window.innerWidth >= 768) {
      // we don't want this to override the onClick if a macro is clicked
      // is there a non-hacky way to do this?
      setTimeout(() => {
        if (showMacros) {
          setShowMacrosWithPermission(false);
        }
      }, 500);
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === KEYS.RETURN_KEYCODE) {
      if (e.shiftKey) {
        e.preventDefault();
        handleSendButtonClick();
      }
    }
    if (e.keyCode === KEYS.M_KEYCODE && e.ctrlKey) {
      // show or hide the macro menu on ctrl+m
      setShowMacrosWithPermission(!showMacros);
      setMacroFilter('');
    }
    // esc key
    if (e.keyCode === KEYS.ESC_KEYCODE) {
      setShowMacrosWithPermission(false);
    }
    if (showMacros) {
      let nextIndex;
      // handle keypresses for macro navigation
      // up => 38
      // down => 40
      const numMacros = visibleMacros.length;
      if (e.keyCode === KEYS.RETURN_KEYCODE && selectedMacro !== -1) {
        e.preventDefault();
        const macro = visibleMacros[selectedMacro];
        handleMacroClick(processMacro(macro.text));
      } else if (e.keyCode === KEYS.UP_KEYCODE) {
        nextIndex =
          selectedMacro !== -1 ? (selectedMacro + numMacros - 1) % numMacros : numMacros - 1;
        setSelectedMacro(nextIndex);
      } else if (e.keyCode === KEYS.DOWN_KEYCODE) {
        nextIndex = selectedMacro !== -1 ? (selectedMacro + 1) % numMacros : 0;
        setSelectedMacro(nextIndex);
      }
    }
  };

  const handleMacroClick = (text: string) => {
    setChatInput(text);
    setShowMacrosWithPermission(false);
    textAreaElement.current?.focus();
  };

  /* Copilot */

  const copilotSuggestion = useParameterizedSelector(getPatientCopilotSuggestion, patient.id);
  const copilotLoadingState = useParameterizedSelector(getPatientCopilotLoadingState, patient.id);
  const copilotCanRegenerate = useParameterizedSelector(getPatientCopilotCanRegenerate, patient.id);
  const copilotError = useParameterizedSelector(getPatientCopilotError, patient.id);
  const copilotMaximized = useParameterizedSelector(getPatientCopilotMaximized, patient.id);

  const handleCopilotInsert = () => {
    setChatInput(chatInput + (chatInput && ' ') + copilotSuggestion);

    // Clear copilot suggestion
    removeCopilotSuggestion(patient.id, dispatch);

    GrdnApi.createEvent({
      eventGroup: EventGroupEnum.chat,
      eventName: AiEventName.insertSuggestion,
      eventData: { patientId: patient.id, suggestion: copilotSuggestion },
    });
  };

  const generateCopilotSuggestion = async () => {
    dispatch(getCopilotSuggestion(patient.id));
  };

  const regenerateCopilotSuggestion = () => {
    GrdnApi.createEvent({
      eventGroup: EventGroupEnum.chat,
      eventName: AiEventName.clickToRegenerateSuggestion,
      eventData: { patientId: patient.id },
    });
    generateCopilotSuggestion();
  };

  const minMaxCopilotSuggestion = () => {
    setCopilotMaximizedState(patient.id, copilotMaximized, dispatch);
  };

  /* End Copilot */

  const shouldShowSendButton = () => {
    return (
      (!isDeactivated || !isPatientChat) &&
      ((chatInput && chatInput.length > 0) || Object.values(isFocused).some((i) => i))
    );
  };

  const renderMacros = () => {
    visibleMacros = [];
    let visibleIndex = 0;
    return macros.map((macro) => {
      const item = macro;
      if (
        (MACRO_DISPLAY_LIMIT && visibleIndex === MACRO_DISPLAY_LIMIT) ||
        (macroFilter !== '' &&
          item.title.toLowerCase().indexOf(macroFilter.toLowerCase()) === -1 &&
          item.text.toLowerCase().indexOf(macroFilter.toLowerCase()) === -1)
      ) {
        return null;
      }
      const selected = visibleIndex === selectedMacro;
      const title = item.title;
      const text = processMacro(item.text);
      visibleMacros.push(macro);
      visibleIndex += 1;
      return (
        <Menu.Item
          key={item.id}
          className={css.macroItem}
          onClick={() => {
            handleMacroClick(text);
          }}
          active={selected}
        >
          <Header as="h5">{title}</Header>
          {truncate(text, { length: MACRO_CHAR_LIMIT, omission: '...' })}
        </Menu.Item>
      );
    });
  };

  let formattedPatientPlaceholder;
  if (isPatientProfileLoading) {
    formattedPatientPlaceholder = '';
  } else if (isPatientChatAndDisabled) {
    formattedPatientPlaceholder = 'Chat is disabled for deactivated patients.';
  } else if (profileIsError(patientProfile)) {
    formattedPatientPlaceholder = 'Message Patient (Shift + Enter)';
  } else {
    formattedPatientPlaceholder = `Message ${
      patientProfile.preferredName || patientProfile.firstName
    } ${patientProfile.lastName} (Shift + Enter)`;
  }
  const formattedPlaceholder = isPatientChat
    ? formattedPatientPlaceholder
    : 'Message Care Team (Shift + Enter)';
  const patientLocationKey: StateKey | undefined = patientLocation && StateKey[patientLocation];
  const errorLoadingPatient = !isPatientProfileLoading && profileIsError(patientProfile);
  const patientLocationUnknown = !isPatientProfileLoading && !patientLocationKey;

  // Inner Composer container, including Chat Copilot and text box containing
  // send button, pills and status dropdown
  const textArea = (
    <div>
      {chatCopilot && isPatientChat && copilotLoadingState && (
        <ChatCopilot
          onInsert={handleCopilotInsert}
          onRegenerate={regenerateCopilotSuggestion}
          onMinMax={minMaxCopilotSuggestion}
          suggestion={copilotSuggestion}
          loadingState={copilotLoadingState}
          maximized={copilotMaximized}
          canRegenerate={copilotCanRegenerate}
          error={copilotError}
        />
      )}
      <div className={cx(css.composerContainer, isPatientChatAndDisabled && css.disabled)}>
        <div className={cx(css.inputContainer, !isPatientChat && css.providerInput)}>
          <div className={css.textboxContainer}>
            <TextareaAutosize
              ref={textAreaElement}
              className={cx(formCss.field, css.chatInput, isPatientChatAndDisabled && css.disabled)}
              placeholder={formattedPlaceholder}
              value={chatInput}
              onFocus={() => handleOnFocus(FocusableComponents.textArea)}
              onBlur={handleTextAreaBlur}
              onChange={handleChatInputChange}
              onKeyDown={handleKeyPress}
              maxRows={10}
              data-testid="TextArea"
              disabled={isPatientChatAndDisabled}
            />
          </div>
          <div>
            <div className={cx(css.buttonContainer, !isPatientChat && css.providerInput)}>
              {isPatientChat && <RequestToScheduleVisitButton disabled={isDeactivated} />}
              {isPatientChat && (
                <VideoButton channel={channel as Channel} disabled={isDeactivated} />
              )}
              <AttachButtonDeprecated
                isPatientChat={isPatientChat}
                updateIsUploading={updateIsUploading}
                disabled={isDeactivated}
              />
              {shouldShowSendButton() && (
                <div
                  onMouseDown={() => handleOnFocus(FocusableComponents.sendButton)}
                  onBlur={() => handleOnBlur(FocusableComponents.sendButton)}
                >
                  <SendButton handleSendButtonClick={handleSendButtonClick} />
                </div>
              )}
            </div>
          </div>
        </div>
        {isPatientChat && (
          <div className={css.bottomButtons}>
            <div className={css.bottomPills}>
              {!errorLoadingPatient && !patientLocationUnknown && (
                <ProfilePicture image={myProvider?.headshotUrl} />
              )}
              {isPatientProfileLoading || hasVideoVisitsLoadingState === LoadingState.Loading ? (
                <PillLoader />
              ) : (
                <>
                  {errorLoadingPatient ? (
                    <ErrorPill />
                  ) : (
                    <>
                      <LicensingPill
                        patientLocationKey={patientLocationKey}
                        provider={myProvider}
                      />
                      <CloseKnitPill
                        isCloseKnitProvider={isCloseKnitProvider}
                        isCloseKnitPatient={isCloseKnitPatient}
                      />
                      <CareteamPill
                        providerCareteam={providerCareteam}
                        patientCareteamId={patientCareteamId}
                      />
                      <FaceToFacePill
                        patientHasHadVideoVisit={patientHasHadVideoVisit}
                        patientVisits={visits}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div className={css.statusDropdown}>
              <StatusDropdown />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Form style={styles.container}>
      <Progress style={isUploading ? styles.progress : styles.hiddenProgress} percent={100} />

      <div className={css.upperLabels}>
        <div className={css.upperLabelContent}>
          <TypingIndicator channel={channel} patientRecord={patient} />
        </div>
      </div>
      <Popup
        basic
        className={`vertical menu ${css.macroPopup}`}
        hoverable
        on="click"
        open={showMacros}
        trigger={textArea}
      >
        {patient && user && renderMacros()}
      </Popup>
    </Form>
  );
}
