import { DateTime } from 'luxon';
import * as React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { getMyProvider } from 'reducers/user';
import { StateKey } from 'types/geo';
import css from 'components/features/Sidebar/Field.module.css';
import { getHasChannelMessageDraft } from 'reducers/channels';
import { Pill, PillColors, ProfilePictureGroup } from 'components/ui/Pills';
import { CheckCircleIcon, OnlineIndicator, Pencil } from 'components/ui/svg';
import { BasicTypingIndicator } from 'components/ui/TypingIndicator';
import { useActiveMRN, useParameterizedSelector } from 'lib/hooks';
import { formatDisplayTime, isProviderLicensed } from 'lib/util';
import { providersSelectors } from 'reducers/providers';
import { SidebarChannel } from 'types/tables/channels';
import { Patient } from 'types/tables/patients';
import { Provider } from 'types/tables/providers';
import { Bucket } from 'types/sendbird/chat';
import {
  getPatientCareteamId,
  getPatientCurrentState,
  getPatientName,
  isPatientDeactivated,
} from 'reducers/patients';
import { getCareteamName } from 'reducers/careteams';
import { AdminMessageType, Message, PrimaryMessageType } from 'types/tables/messages';

const messageText = (msg: Message): string => {
  switch (msg.type) {
    case PrimaryMessageType.Image:
      return ' sent an image';
    case PrimaryMessageType.Video:
      return ' started a video call';
    case PrimaryMessageType.Text:
    case AdminMessageType.AfterHours: // legacy
    case AdminMessageType.Appointment: // legacy
    case AdminMessageType.CovidVaxEligible: // legacy
    case AdminMessageType.Text:
      return `: ${msg.data.text}`;
    case PrimaryMessageType.ScreenerCompleted:
      return ' completed a screener';
    default:
      return ' sent a message';
  }
};

const messagePreview = (sidebarChannel: SidebarChannel) => {
  const { channel, patient } = sidebarChannel;
  const lastMessage = channel.lastMessage;
  if (!lastMessage) return '';
  const sender = lastMessage.senderId === patient.sendbirdUserId ? 'Patient' : 'Care Team';
  return `${sender}${messageText(lastMessage)}`;
};

interface FieldProps {
  onClick: (patient: Patient) => void;
  bucket: Bucket;
  channel: SidebarChannel;
  providers: Provider[];
}

export const Field = (props: FieldProps) => {
  const { channel: sidebarChannel, onClick, providers, bucket } = props;
  const { channel, patient } = sidebarChannel;
  const activeMrn = useActiveMRN();
  const hasMessageDraft = useParameterizedSelector(getHasChannelMessageDraft, channel.id);
  const providerRecords = useSelector(providersSelectors.selectEntities);
  const providerIds = useSelector(providersSelectors.selectIds);
  const patientCareteamId = useParameterizedSelector(getPatientCareteamId, patient.id);
  const patientCareteamName = useParameterizedSelector(getCareteamName, patientCareteamId);
  const patientDeactivationState = useParameterizedSelector(isPatientDeactivated, patient.id);

  const myProvider = useSelector(getMyProvider);
  const patientName = useParameterizedSelector(getPatientName, patient.id);
  const patientLocation = useParameterizedSelector(getPatientCurrentState, patient.id);
  const patientLocationKey: StateKey | undefined = patientLocation && StateKey[patientLocation];
  const isMyProviderLicensed = isProviderLicensed(myProvider, patientLocationKey);

  if (!patient || !channel) return null;

  const mrn = patient.mrn;
  const name = patientName || mrn.toString();
  // Return early if no valid name or `mrn` available.
  if (!name) {
    return (
      <div className={css.mainSidebarRoomItem} key={mrn}>
        Loading...
      </div>
    );
  }
  const assignedProviders = patient.assigned || [];
  const status = channel.status;
  const updatedAt = channel.lastMessage ? channel.lastMessage.timestamp : undefined;
  const currentlySelected = activeMrn === mrn;
  const draftIcon = hasMessageDraft ? (
    <div className={css.pencilIcon}>
      <Pencil />
    </div>
  ) : (
    <OnlineIndicator online={channel.isPatientOnline} />
  );
  const label = (
    <div className={`${css.label} ${css[status]}`} data-testid={`StatusLabel-${status}`}>
      {status.charAt(0).toUpperCase()}
    </div>
  );
  const licensingIndicator = (
    <div className={css.licensingIndicator} data-testid="LicensingIndicator">
      <CheckCircleIcon height={9} width={9} />
    </div>
  );
  const indicator = (
    <div data-testid="MessagePreview" className={css.timestamp}>
      {BasicTypingIndicator({
        ...props,
        sidebarChannel,
        providers: providerRecords,
        providerIds,
      }) || (
        <div className={css.truncatedText}>
          {updatedAt && formatDisplayTime(DateTime.fromMillis(updatedAt))}
          {bucket === Bucket.assigned
            ? updatedAt && ' • '
            : updatedAt && patientCareteamName && ' • '}
          {bucket === Bucket.assigned
            ? messagePreview(sidebarChannel)
            : patientCareteamName && `${patientCareteamName}`}
        </div>
      )}
    </div>
  );
  const mrnText = <div className={`${css.mrn} ${status === css.inactive}`}>{mrn}</div>;
  const darker = currentlySelected;

  let thisChatsAssignedProviders: Provider[] = [];
  if (assignedProviders) {
    thisChatsAssignedProviders = providers.filter((c) => assignedProviders.includes(c.id));
  }

  return bucket === Bucket.archived ? (
    <div
      className={cx(
        css.mainSidebarRoomItem,
        css[status],
        css.inactive,
        currentlySelected && css.selected,
      )}
      key={mrn}
      onClick={() => {
        onClick(patient);
      }}
      data-testid="PatientMenuItem"
    >
      <div className={css.roomItemInfoAndProfile}>
        <div className={css.roomItemInfo}>
          <span>{name}</span>
          <Pill darker={darker}>{mrnText}</Pill>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={cx(
        css.mainSidebarRoomItem,
        css[status],
        currentlySelected && css.selected,
        css.legacy,
      )}
      key={mrn}
      onClick={() => {
        onClick(patient);
      }}
      data-testid="PatientMenuItem"
    >
      <div className={`${css.icon} ${css[status]}`}>{draftIcon}</div>
      <div className={css.roomInfo}>
        <div className={css.roomItemInfoAndProfile}>
          <div>
            <span>{name}</span>
            <Pill darker={darker}>{mrnText}</Pill>
            <Pill color={status === 'open' ? PillColors.red : PillColors.grey}>{label}</Pill>
            {patientDeactivationState && <Pill color={PillColors.grey}>Deactivated</Pill>}
            {(bucket === Bucket.unassigned || bucket === Bucket.routed) && isMyProviderLicensed && (
              <Pill color={PillColors.green}>{licensingIndicator}</Pill>
            )}
          </div>
          <ProfilePictureGroup providers={thisChatsAssignedProviders} size="small" maxPills={2} />
        </div>
        {indicator}
      </div>
    </div>
  );
};
