import React, { useCallback, useContext, useState } from 'react';
import { SearchResultProps } from 'semantic-ui-react';
import { debounce, DebouncedFunc, get } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import cx from 'classnames';
import css from 'components/features/Sidebar/Sidebar.module.css';
import Navigation from 'components/features/Sidebar/Navigation';
import { ReactiveMenu } from 'components/features/Sidebar/ReactiveMenu';
import Section from 'components/features/Sidebar/Section';
import MultiFilterView, { MultiFilterViewSize } from 'components/features/Sidebar/MultiFilterView';
import { getMyProviderId } from 'reducers/user';
import { loadEnv } from 'lib/env';
import { escapeRegExp } from 'lib/util';
import { Search } from 'components/ui/Search';
import { Bucket, BucketFilterOption } from 'types/sendbird/chat';
import { SidebarChannel } from 'types/tables/channels';
import { carepodsSelectors } from 'reducers/carepods';
import { getSystemProviders, providersSelectors } from 'reducers/providers';
import {
  getArchivedSidebarChannels,
  getAssignedUnroutedSidebarChannels,
  getRoutedSidebarChannels,
  getSidebarChannels,
  getUnassignedSidebarChannels,
} from 'reducers/channels';
import { AppContext } from 'lib/hooks';
import { useSideBarPoll } from 'reducers/sidebar';
import {
  assignmentFilterOptions,
  generateAssignmentFilters,
  generateRoutingFilters,
  generateUnassignedFilters,
} from 'lib/sidebarChannelFiltering';

interface SearchResult extends SearchResultProps {
  mrn: number;
  name: string;
}

export interface SidebarProps {
  selectPatient: DebouncedFunc<(mrn: number) => Promise<void>>;
}

const SideBarSearch = ({
  selectPatient,
  setIsOpen,
}: SidebarProps & { setIsOpen: (boolean) => void }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [value, setValue] = useState<string>('');
  const sidebarChannels = useSelector(getSidebarChannels);

  const handleResultSelect = async (
    _: React.MouseEvent<HTMLDivElement, MouseEvent>,
    { result }: { result: SearchResult },
  ) => {
    const mrn = result.mrn;
    try {
      await selectPatient(mrn);
      setIsOpen(false);
      setValue('');
    } catch (e) {}
  };

  const handleSearchChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    { value }: { value: string },
  ) => {
    setIsLoading(true);
    setValue(value);
    setTimeout(() => {
      const searchTerm = value;
      const re = new RegExp(escapeRegExp(searchTerm), 'i');
      const results =
        sidebarChannels.length > 0
          ? sidebarChannels
              .filter(
                (channel: SidebarChannel) =>
                  re.test(channel.patientName || '') || re.test(String(channel.patient.mrn)),
              )
              .map(({ patient, patientName }) => ({
                name: patientName || '',
                mrn: patient.mrn,
                title: patientName,
              }))
          : [];
      const resultsList = results.map((result) => ({
        key: `result_${result.mrn}`,
        mrn: result.mrn,
        title: result.name || result.mrn.toString(),
        name: result.name,
      }));
      const searchTermAsMrn = parseInt(searchTerm);
      const resultsWithSearchTerm = isNaN(searchTermAsMrn)
        ? resultsList
        : [
            ...resultsList,
            {
              key: `jumpTo_${searchTermAsMrn}`,
              mrn: searchTermAsMrn,
              title: searchTermAsMrn.toString(),
              name: 'Jump to:',
            },
          ];
      setResults(resultsWithSearchTerm);
      setIsLoading(false);
    }, 300);
  };

  const renderResult = (room: SearchResult) => {
    return (
      <div>
        {room.name} {room.mrn}
      </div>
    );
  };

  return (
    <Search
      data-testid="SidebarSearch"
      loading={isLoading}
      placeholder="Search by Name or MRN"
      onResultSelect={handleResultSelect}
      onSearchChange={debounce(handleSearchChange, 500, { leading: true })}
      resultRenderer={renderResult}
      results={results}
      selectFirstResult
      showNoResults
      value={value || ''}
    />
  );
};

export default function Sidebar({ selectPatient }: SidebarProps) {
  const { mobileChatRoom, toggleMobileChatRoom } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const providers = useSelector(providersSelectors.selectAll);
  const carepods = useSelector(carepodsSelectors.selectAll);
  const systemProviders = useSelector(getSystemProviders);

  const assignedChannels = useSelector(getAssignedUnroutedSidebarChannels);
  const unassignedChannels = useSelector(getUnassignedSidebarChannels);
  const archivedChannels = useSelector(getArchivedSidebarChannels);
  const routedChannels = useSelector(getRoutedSidebarChannels);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [settingsPrevLocation, setSettingsPrevLocation] = useState<string>('/app');
  const [selectedCarepods, setSelectedCarepods] = useState<string[]>([]);
  const [selectedSystemProviders, setSelectedSystemProviders] = useState<string[]>([]);
  const [assignmentFilter, setAssignmentFilter] = useState<string>(assignmentFilterOptions.all);
  const activeUserProviderId = useSelector(getMyProviderId) || '';

  const unassignedFilters = generateUnassignedFilters(unassignedChannels, carepods);

  const assignmentFilters = generateAssignmentFilters(
    assignedChannels,
    providers,
    activeUserProviderId,
  );

  const routingFilters = generateRoutingFilters(routedChannels, systemProviders);
  const routingFiltersByCarepod: BucketFilterOption[] = routingFilters.map((option) => {
    return {
      filter: option.filter,
      patients: option.patients.filter(({ patient }) => {
        const patientCarepod = carepods.find((carepod) => carepod.id === patient.carePodId);
        return patientCarepod && selectedCarepods.includes(patientCarepod.name);
      }),
    };
  });

  // Filtered channels

  const unassignedChannelsFiltered = unassignedFilters.reduce<SidebarChannel[]>(
    (combinedChannels, unassignedFilter) => {
      if (selectedCarepods.includes(unassignedFilter.filter)) {
        return [...combinedChannels, ...unassignedFilter.patients];
      }
      return combinedChannels;
    },
    [],
  );

  const assignedChannelsFiltered = get(
    assignmentFilters.find((f) => f.filter === assignmentFilter),
    'patients',
  );

  const isCarepodsFiltered = selectedCarepods.length > 0;
  const isSystemProvidersFiltered = selectedSystemProviders.length > 0;

  const routedChannelsFiltered = routingFilters.reduce<SidebarChannel[]>(
    (combinedChannels, routingFilter) => {
      const filteredChannels = routingFilter.patients.filter(({ patient }) => {
        const patientCarepod = carepods.find((carepod) => carepod.id === patient.carePodId);
        if (combinedChannels.some((existingChannel) => existingChannel.patient.id === patient.id)) {
          return false;
        } else if (isCarepodsFiltered && isSystemProvidersFiltered) {
          return (
            selectedSystemProviders.includes(routingFilter.filter) &&
            patientCarepod &&
            selectedCarepods.includes(patientCarepod.name)
          );
        } else if (!isCarepodsFiltered && isSystemProvidersFiltered) {
          return selectedSystemProviders.includes(routingFilter.filter);
        } else if (isCarepodsFiltered && !isSystemProvidersFiltered) {
          return patientCarepod && selectedCarepods.includes(patientCarepod.name);
        } else {
          return true;
        }
      });
      return [...combinedChannels, ...filteredChannels];
    },
    [],
  );

  useSideBarPoll();

  const onSidebarItemClick = useCallback(
    async (sidebarItem: Record<string, string>): Promise<void> => {
      setIsOpen(false);
      await selectPatient(parseInt(sidebarItem.mrn));
    },
    [selectPatient],
  );

  const goHome = () => {
    navigate('/app');
  };

  const toggleSettings = (menuActive) => {
    if (menuActive) {
      navigate(settingsPrevLocation);
      return;
    }
    setSettingsPrevLocation(location.pathname);
    navigate('/app/settings');
  };

  const handlePickAssignmentFilter = (filter: string) => {
    setAssignmentFilter(filter);
  };

  const handlePickCarepodFilter = (filter: string) => {
    if (selectedCarepods.indexOf(filter) > -1) {
      setSelectedCarepods(selectedCarepods.filter((carepodFilter) => carepodFilter !== filter));
    } else {
      setSelectedCarepods([...selectedCarepods, filter]);
    }
  };

  const handlePickRoutingFilter = (filter: string) => {
    if (selectedSystemProviders.indexOf(filter) > -1) {
      setSelectedSystemProviders(
        selectedSystemProviders.filter((routingFilter) => routingFilter !== filter),
      );
    } else {
      setSelectedSystemProviders([...selectedSystemProviders, filter]);
    }
  };

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleChats = () => {
    if (toggleMobileChatRoom) {
      toggleMobileChatRoom(mobileChatRoom);
    }
  };

  const matchPath = (pathname: string) => {
    if (pathname) {
      if (pathname.match(/\/app\/settings/)) {
        return 'settings';
      }
    }
    return 'messages';
  };

  const isSettingsOpen = () => {
    return matchPath(location.pathname) === 'settings';
  };

  return (
    <>
      <ReactiveMenu
        isOpen={isOpen}
        handleToggleChats={handleToggleChats}
        handleToggleSidebar={handleToggleSidebar}
        testId="SidebarReactiveMenu"
      />
      <div className={cx(css.sidebar, loadEnv('REACT_APP_LOCAL_PROD') && css.localProdMode)}>
        <Navigation
          testId="SidebarNavigation"
          menuActive={isSettingsOpen()}
          toggleSettings={toggleSettings}
          goHome={goHome}
        />
        <div style={{ marginLeft: 12 }}>
          <SideBarSearch selectPatient={selectPatient} setIsOpen={setIsOpen} />
        </div>

        <div className={css.multiFilterView}>
          <MultiFilterView
            filters={unassignedFilters}
            selectedFilters={selectedCarepods}
            onSelectFilter={handlePickCarepodFilter}
            placeholder={'Filter by Care Region'}
            size={MultiFilterViewSize.large}
          />
        </div>

        <div className={css.patients}>
          <Section
            channels={selectedCarepods.length > 0 ? unassignedChannelsFiltered : unassignedChannels}
            onClick={onSidebarItemClick}
            bucket={Bucket.unassigned}
            providers={providers}
            showSectionOnLoad
          />
          <Section
            channels={routedChannelsFiltered}
            onClick={onSidebarItemClick}
            bucket={Bucket.routed}
            onPickFilter={handlePickRoutingFilter}
            providers={providers}
            filters={selectedCarepods.length > 0 ? routingFiltersByCarepod : routingFilters}
            selectedFilters={selectedSystemProviders}
            showSectionOnLoad
          />
          <Section
            channels={assignedChannelsFiltered}
            onClick={onSidebarItemClick}
            bucket={Bucket.assigned}
            onPickFilter={handlePickAssignmentFilter}
            filters={assignmentFilters}
            selectedFilters={[assignmentFilter]}
            providers={providers}
            showSectionOnLoad
          />
          <Section
            channels={archivedChannels}
            onClick={onSidebarItemClick}
            bucket={Bucket.archived}
            providers={providers}
            showSectionOnLoad={false}
          />
        </div>
      </div>
    </>
  );
}
