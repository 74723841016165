import { noop } from 'lodash';
import { AxiosResponse, CancelToken } from 'axios';
import { DocumentData } from 'components/features/Composer/AttachButtonTypes';
import { Careteam } from 'types/tables/careteams';
import { SendbirdMessage } from 'types/sendbird';
import Api from 'lib/api';
import DocumentApi from 'lib/documentApi';
import {
  AddonCategoriesPayload,
  AppointmentReasonsPayload,
  AuthPayload,
  BradStatusPayload,
  CareteamPayload,
  ChannelUpdatePayload,
  ChartPayload,
  CompleteTicklerPayload,
  SuggestionPayload,
  DocumentPayload,
  EdenScreenerResponsePayload,
  EventPayload,
  ExternalAddonsPayload,
  FetchNextStepsPayload,
  GenerateAVSPayload,
  GetPatientTicklersPayload,
  GetSponsorDivisionsPayload,
  HeadshotPayload,
  ImagePayload,
  JoinPatientChannelsPayload,
  LDHashPayload,
  PatientAccountInfoPayload,
  PatientDocumentMetadataPayload,
  PatientHasHadVideoVisitsPayload,
  PatientPayload,
  PharmacyPayload,
  ResolveNextStepPayload,
  RosterStatus,
  SaveAVSPayload,
  ScreenerPayload,
  SideBarPayload,
  Tickler,
  UpdateDocumentPayload,
  UpdateTriageStatusPayload,
  VideoCallPayload,
  VideoVisitPayload,
} from 'types/grdn';
import { APIResponse } from 'types/api';
import { Provider } from 'types/tables/providers';
import { ProviderGroup } from 'types/tables/providerGroups';
import { Sponsor } from 'types/tables/sponsors';
import { captureBreadcrumb } from 'lib/sentry';
import { PatientTriageStatus, ProfilePayload, TriageStatus } from 'types/tables/profiles';
import { ChannelStatus, GrdnSendbirdChannel } from 'types/tables/channels';
import { PrimaryKey } from 'types/tables/base';
import { AppointmentNote, Visit, ZoomMeetingPayload } from 'types/athena/visits';
import { Insurance, InsuranceCard, InsuranceOld } from 'types/athena/insurance';
import { Macro } from 'types/tables/macros';
import { loadEnv } from 'lib/env';
import { Popup } from 'types/tables/popups';
import { AthenaProvider } from 'types/athena/athenaProvider';
import {
  DraftNextStep,
  NextStep,
  NextStepResolution,
} from 'components/features/CarePlan/CarePlanTypes';
import { AppointmentScreeners } from 'types/tables/appointmentScreeners';
import { RetrievedConfiguration } from 'types/tables/configuration';
import { DocumentCategoryType, DocumentMetadata, DownloadLocation } from 'types/tables/documents';
import { PatientId } from 'types/tables/patients';
import { Carepod } from 'types/tables/carepods';

export const api = new Api(loadEnv('REACT_APP_SERVER_URL'));
export const documentApi = new DocumentApi(loadEnv('REACT_APP_SERVER_URL'));

export const search = async (
  params: Record<string, unknown>,
): Promise<APIResponse<PatientPayload>> => await api.get(`v2/patient?${api.objectToQuery(params)}`);

export const profile = async (patientId: PrimaryKey): Promise<APIResponse<ProfilePayload>> =>
  await api.get(`v2/patient/${patientId}/profile`);

export const chart = async (patientId: PrimaryKey): Promise<APIResponse<ChartPayload>> =>
  await api.get(`v2/patient/${patientId}/chart`);

export const pharmacy = async (patientId: PrimaryKey): Promise<APIResponse<PharmacyPayload>> =>
  await api.get(`v2/patient/${patientId}/pharmacy`);

export const hasHadVideoVisits = async (
  patientId: PrimaryKey,
): Promise<APIResponse<PatientHasHadVideoVisitsPayload>> =>
  await api.get(`v2/patient/${patientId}/video-messages`);

export const image = async (
  patientId: PrimaryKey,
  imageId: string,
  cancelToken: CancelToken,
): Promise<APIResponse<ImagePayload>> =>
  await api.get(`v2/image/${patientId}/${imageId}`, cancelToken);

export const uploadImage = async (
  patientId: PrimaryKey,
  channelId: PrimaryKey,
  image: string,
): Promise<APIResponse<ImagePayload>> =>
  await api.post(`v2/image/${patientId}`, { channelId, image });

export const getDocumentTypes = async (): Promise<APIResponse<DocumentCategoryType[]>> =>
  await api.get(`/v2/document/type`);

export const getDocumentMetadata = async (
  documentId: string,
): Promise<APIResponse<DocumentMetadata>> =>
  await api.get(`/v2/document/${documentId}?=metadata_only=true`);

export const getPatientDocumentMetadata = async (
  patientId: string,
): Promise<APIResponse<PatientDocumentMetadataPayload>> =>
  await api.get(`/v2/document?patient-id=${patientId}`);

export const getDocument = async (
  documentId: string,
  location: DownloadLocation,
): Promise<APIResponse<Blob>> =>
  await documentApi.get(`/v2/document/${documentId}/download?source=${location}`);

export const uploadDocument = async (data: DocumentData): Promise<APIResponse<DocumentPayload>> => {
  return await documentApi.post(`v2/document`, data);
};

export const updateDocument = async (
  documentId: string,
  payload: Partial<UpdateDocumentPayload>,
): Promise<APIResponse<undefined>> => {
  return await api.put(`/v2/document/${documentId}`, payload);
};

export const newDigitalVisit = async (
  patientId: PrimaryKey,
  appointmentTypeId?: number,
): Promise<APIResponse<any>> => await api.post(`v2/appointment`, { patientId, appointmentTypeId });

export const getDigitalVisit = async (
  patientId: PrimaryKey,
): Promise<APIResponse<Record<string, any>>> =>
  await api.get(`v2/appointment?patient-id=${patientId}`);

export const checkoutDigitalVisit = async (
  appointmentId: string,
  patientId?: string,
): Promise<APIResponse<APIResponse<any>>> =>
  await api.put(`v2/appointment/${appointmentId}`, { patientId });

export const sendAfterVisitSummary = async (
  appointmentId: string,
  content: string,
): Promise<APIResponse<APIResponse<any>>> =>
  await api.post(`v2/appointment/${appointmentId}/after-visit-summary`, { content });

export const retractAfterVisitSummary = async (
  appointmentId: string,
): Promise<APIResponse<APIResponse<any>>> =>
  await api.delete(`v2/appointment/${appointmentId}/after-visit-summary`);

export const getAppointmentScreeners = async (
  appointmentId: string,
): Promise<APIResponse<AppointmentScreeners>> =>
  await api.get(`v2/appointment/${appointmentId}/check-in`);

export const appointmentCheckIn = async (
  appointmentId: string,
  patientId: PrimaryKey,
  screenerNames: string[],
): Promise<APIResponse<APIResponse<any>>> => {
  return await api.post(`v2/appointment/${appointmentId}/check-in`, { patientId, screenerNames });
};

export const requestCaseFeedback = async (
  patientId: PrimaryKey,
  caseId: string,
): Promise<APIResponse<string>> => await api.post(`v2/case/${caseId}/feedback`, { patientId });

export const headshot = async (patientId: PrimaryKey): Promise<APIResponse<HeadshotPayload>> =>
  await api.get(`v2/patient/${patientId}/headshot`);

export const primaryInsurance = async (patientId: PrimaryKey): Promise<APIResponse<Insurance>> =>
  await api.get(`v2/patient/${patientId}/insurance`);

export const primaryInsuranceOld = async (
  patientId: PrimaryKey,
): Promise<APIResponse<InsuranceOld>> => await api.get(`v2/patient/${patientId}/insurance`);

export const primaryInsuranceCard = async (
  patientId: PrimaryKey,
): Promise<APIResponse<InsuranceCard>> =>
  await api.get(`v2/patient/${patientId}/document/primary-insurance-card`);

export const checkInsuranceEligibility = async (
  patientId: PrimaryKey,
  insuranceId: PrimaryKey,
): Promise<APIResponse<string>> =>
  await api.put(`v2/patient/${patientId}/insurance/${insuranceId}/eligibility`, {});

export const visits = async (patientId: PrimaryKey): Promise<APIResponse<Visit[]>> =>
  await api.get(`v2/patient/${patientId}/visits`);

export const encounter = async (
  patientId: PrimaryKey,
  encounterId: PrimaryKey,
): Promise<APIResponse<Visit>> => await api.get(`v2/patient/${patientId}/encounter/${encounterId}`);

export const appointmentNote = async (
  appointmentId: string,
): Promise<APIResponse<AppointmentNote>> => await api.get(`v2/appointment/${appointmentId}/note`);

export const clinicianDetails = async (): Promise<APIResponse<Provider[]>> =>
  await api.get(`v2/clinician-details`);

export const sponsors = async (): Promise<APIResponse<Sponsor[]>> => await api.get(`v2/sponsors`);

export const serviceWhitelist = async (): Promise<AxiosResponse> =>
  await api.get(`v2/service-whitelist`);

export const macros = async (): Promise<APIResponse<Macro[]>> => await api.get(`v2/macro`);

export const createMacro = async (title: string, text: string): Promise<APIResponse<Macro>> =>
  await api.post('v2/macro', { title, text });

export const editMacro = async (
  id: string,
  title: string,
  text: string,
): Promise<APIResponse<Macro>> => await api.put(`v2/macro/${id}`, { title, text });

export const deleteMacro = async (id: string): Promise<APIResponse<number>> =>
  await api.delete(`v2/macro/${id}`);

export const assignProviders = async (
  patientId: PrimaryKey,
  providerIds: PrimaryKey[],
): Promise<AxiosResponse> => await api.post(`v2/patient/${patientId}/assign`, { providerIds });

export const unassignProviders = async (
  patientId: PrimaryKey,
  providerIds: PrimaryKey[],
): Promise<AxiosResponse> => await api.post(`v2/patient/${patientId}/unassign`, { providerIds });

export const updateChannel = async (
  channelId: PrimaryKey,
  status: ChannelStatus,
): Promise<APIResponse<ChannelUpdatePayload>> =>
  await api.put(`v2/channel/${channelId}`, { status });

export const deleteMessage = async (
  channelId: PrimaryKey,
  messageId: string,
  patientId: string,
  sentAt: number,
  unread: boolean,
  content?: string,
  imageId?: string,
  documentId?: string,
  deletedReason?: string,
  providerSenderId?: string,
): Promise<APIResponse<any>> =>
  await api.post(`v2/channel/${channelId}/message/${messageId}/delete`, {
    patientId,
    sentAt,
    unread,
    content,
    imageId,
    documentId,
    deletedReason,
    providerSenderId,
  });

export const sideBar = async (prevLastUpdatedAt = NaN): Promise<APIResponse<SideBarPayload>> => {
  const url = !isNaN(prevLastUpdatedAt)
    ? `v2/sidebar?prev-last-updated-at-in-ms=${prevLastUpdatedAt}`
    : `v2/sidebar`;
  return await api.get(url);
};

export const joinVideoCall = async (
  zoomMeetingId: string,
): Promise<APIResponse<VideoCallPayload>> => {
  const url = `v2/meeting/${zoomMeetingId}`;
  return await api.get(url);
};

export const startVideoCall = async (
  patientId: PrimaryKey,
  email: string,
  zoomMeetingId?: string,
): Promise<APIResponse<VideoCallPayload>> =>
  await api.post(`v2/start-meeting`, {
    email,
    patientId,
    zoomMeetingId,
  });

//TODO: APP-8005 remove when zoom-links feature flag is removed
export const startVideoCallDeprecated = async (
  patientId: PrimaryKey,
  email: string,
): Promise<APIResponse<VideoCallPayload>> => await api.post(`v2/meeting`, { email, patientId });

export const joinPatientChannels = async (
  patientId: PrimaryKey,
): Promise<APIResponse<JoinPatientChannelsPayload>> =>
  await api.put(`v2/patient/${patientId}/join`, { patientId });

/*
 * Adapts the structure of `PatientTriageStatus` to match the endpoint's specs.
 */
const getTriageStatusPayload = (triageStatus: PatientTriageStatus): Record<string, any> => {
  switch (triageStatus.status) {
    case TriageStatus.NeverTaken:
    case TriageStatus.Cleared:
    case TriageStatus.Incomplete:
      return { triageStatus: triageStatus.status };
    case TriageStatus.Isolate:
      return {
        triageStatus: triageStatus.status,
        returnToWorkDate: triageStatus.returnToWorkDate,
      };
  }
};

export const updateTriageStatus = async (
  patientId: string,
  triageStatus: PatientTriageStatus,
): Promise<APIResponse<UpdateTriageStatusPayload>> =>
  await api.post(`v2/patient/${patientId}/triage-status`, getTriageStatusPayload(triageStatus));

export const getEdenScreenerResponse = async (
  patientId: PrimaryKey,
): Promise<APIResponse<EdenScreenerResponsePayload>> =>
  await api.get(`v2/patient/${patientId}/screener-response`);

export const getScreener = async (
  screenerResponseId: string,
  covidScreenerId: string,
): Promise<APIResponse<ScreenerPayload>> =>
  await api.get(
    `v2/screener-card?screener-response-id=${screenerResponseId}&covid-screener-id=${covidScreenerId}`,
  );

export const requestVideoVisit = async (
  patientId: string,
  appointmentReasonId: number,
): Promise<APIResponse<SendbirdMessage>> =>
  await api.post(`v2/video-visit`, { patientId, appointmentReasonId });

export const getVideoVisit = async (
  videoVisitId: string,
): Promise<APIResponse<VideoVisitPayload>> =>
  await api.get(`v2/video-visit?video-visit-id=${videoVisitId}`);

export const createEvent = async (event: EventPayload) => {
  api
    .post('v2/event', event)
    .then(() => {
      captureBreadcrumb({
        category: 'grdn/event',
        message: event.eventName,
        data: event.eventData,
      });
      return;
    })
    .catch(noop);
};

export const removeEmployee = async (
  sponsorId: string,
  employeeId: string,
): Promise<AxiosResponse> => {
  const url = `v2/member?sponsor-id=${sponsorId}&employee-id=${employeeId}`;
  return await api.delete(url);
};

export const linkAccountToRoster = async (
  sponsorId: string,
  employeeId: string,
  patientId: string,
): Promise<AxiosResponse> => await api.put('v2/member', { sponsorId, employeeId, patientId });

export const createRdashLogin = async (props: {
  firstName: string;
  lastName: string;
  email: string;
  sponsorId: string;
}): Promise<AxiosResponse> => await api.post(`v2/sponsor-user`, props);

export const uploadRoster = async ({
  sponsorId,
  csvText,
}: {
  sponsorId: string;
  csvText: string;
}): Promise<AxiosResponse> => {
  const url = `v2/sponsor/${sponsorId}/roster`;
  return await api.post(url, { rosterData: csvText });
};

export const authorize = async (): Promise<APIResponse<AuthPayload>> => await api.get('v2/auth');

export const getLaunchdarklyCreds = async (): Promise<APIResponse<LDHashPayload>> =>
  await api.get('v2/launchdarkly-creds');

export const getActivePopups = async (): Promise<APIResponse<Popup[]>> =>
  await api.get('v2/popup?popup-category=active');

export const getDraftPopups = async (): Promise<APIResponse<Popup[]>> =>
  await api.get('v2/popup?popup-category=draft');

export const getPastPopups = async (): Promise<APIResponse<Popup[]>> =>
  await api.get('v2/popup?popup-category=past');

export const createPopup = async (popup: Partial<Popup>): Promise<APIResponse<Popup>> =>
  await api.post('v2/popup', popup);

export const updatePopup = async ({
  popupId,
  payload,
}: {
  popupId: string;
  payload: Partial<Popup>;
}): Promise<APIResponse<Popup>> => {
  return await api.put(`v2/popup/${popupId}`, payload);
};
export const deletePopup = async (popup: Popup): Promise<APIResponse<number>> =>
  await api.delete(`v2/popup/${popup.id}`);

export const getAthenaProviders = async (): Promise<APIResponse<AthenaProvider[]>> =>
  await api.get('v2/athena-provider');

export const getAppointmentReasons = async (
  athenaDepartmentId: string,
): Promise<APIResponse<AppointmentReasonsPayload>> =>
  await api.get(`v2/department/${athenaDepartmentId}/appointment-reasons`);

export const getPatientAccountInfo = async (
  patientId: PrimaryKey,
): Promise<APIResponse<PatientAccountInfoPayload>> =>
  await api.get(`v2/patient/${patientId}/account-info`);

export const updatePatientAccountInfo = async (
  accountInfo,
): Promise<APIResponse<PatientAccountInfoPayload>> =>
  await api.put(`v2/patient/${accountInfo.patientId}/account-info`, accountInfo);

export const getPatientCareteam = async (
  patientId: PrimaryKey,
): Promise<APIResponse<CareteamPayload>> => await api.get(`v2/patient/${patientId}/careteam`);

export const getAllCareteams = async (): Promise<APIResponse<Careteam[]>> =>
  await api.get(`v2/careteams`);

export const getAllProviderGroups = async (): Promise<APIResponse<ProviderGroup[]>> =>
  await api.get(`v2/provider-groups`);

export const updatePatientCareteam = async (
  patientId: PrimaryKey,
  careteamId: PrimaryKey,
  athenaProviderId?: number,
): Promise<APIResponse<null>> => {
  return await api.put(`v2/patient/${patientId}/careteam`, { careteamId, athenaProviderId });
};

export const getRosterStatuses = async (sponsorId): Promise<APIResponse<RosterStatus[]>> =>
  await api.get(`v2/sponsor/${sponsorId}/roster-statuses`);

export const createTickler = async (
  patientId: PrimaryKey,
  athenaProviderId: number,
  dueDate: string,
  note: string,
): Promise<APIResponse<Tickler>> =>
  await api.post(`v2/ticklers`, { patientId, athenaProviderId, dueDate, note });

export const getPatientTicklers = async (
  patientId: PrimaryKey,
): Promise<APIResponse<GetPatientTicklersPayload>> => {
  return await api.get(`v2/patient/${patientId}/ticklers`);
};

export const completeTickler = async ({
  ticklerAthenaId,
  patientId,
  note,
}: {
  ticklerAthenaId: number;
  patientId: PrimaryKey;
  note: string;
}): Promise<APIResponse<CompleteTicklerPayload>> =>
  await api.put(`v2/ticklers/${ticklerAthenaId}/complete`, { patientId, note });

export const deleteTickler = async ({
  ticklerAthenaId,
  patientId,
  note,
}: {
  ticklerAthenaId: number;
  patientId: PrimaryKey;
  note: string;
}): Promise<APIResponse<CompleteTicklerPayload>> =>
  await api.put(`v2/ticklers/${ticklerAthenaId}/delete`, { patientId, note });

export const updateTickler = async (
  patientId: PrimaryKey,
  ticklerAthenaId: number,
  athenaProviderId: number,
  dueDate: string,
  note: string,
): Promise<APIResponse<Tickler>> =>
  await api.put(`v2/ticklers/${ticklerAthenaId}`, { patientId, athenaProviderId, dueDate, note });

export const getPatientNextSteps = async (
  patientId: PrimaryKey,
): Promise<APIResponse<FetchNextStepsPayload>> => {
  return await api.get(`v2/patient/${patientId}/next-steps`);
};

export const createNextStep = async (nextStep: DraftNextStep): Promise<APIResponse<NextStep>> =>
  await api.post(`v2/next-steps`, nextStep);

export const updateNextStep = async (nextStep: DraftNextStep): Promise<APIResponse<NextStep>> =>
  await api.put(`v2/next-steps/${nextStep.nextStepId}`, nextStep);

export const resolveNextStep = async (
  resolution: NextStepResolution,
): Promise<APIResponse<ResolveNextStepPayload>> =>
  await api.post(
    `v2/patient/${resolution.patientId}/next-steps/${resolution.nextStepId}`,
    resolution,
  );

export const getAddonCategories = async (): Promise<APIResponse<AddonCategoriesPayload>> => {
  return await api.get(`/v2/addons/categories`);
};

export const getExternalAddons = async (
  sponsorId: string,
): Promise<APIResponse<ExternalAddonsPayload>> => {
  return await api.get(`/v2/addons/external?state=enabled&sponsor-id=${sponsorId}`);
};

export const getBradStatus = async (): Promise<APIResponse<BradStatusPayload>> => {
  return await api.get(`/v2/brad/status`);
};

export const putBradActive = async (): Promise<APIResponse<null>> =>
  await api.put(`v2/brad/status/active`, {});

export const putBradInactive = async (): Promise<APIResponse<null>> =>
  await api.put(`v2/brad/status/inactive`, {});

export const scheduleMeeting = async ({
  appointmentId,
  patientId,
}: {
  appointmentId: string;
  patientId: string;
}): Promise<APIResponse<ZoomMeetingPayload>> => {
  return await api.post(`v2/schedule-meeting`, {
    appointmentId,
    patientId,
  });
};

export const getConfiguration = async (): Promise<
  APIResponse<Omit<RetrievedConfiguration, 'state'>>
> => {
  return await api.get(`/v2/configuration`);
};

export const getJoinedChannelsInformation = async (): Promise<
  APIResponse<GrdnSendbirdChannel[]>
> => {
  return await api.get(`/v2/channel/joined`);
};

export const generateAVS = async ({
  patientId,
  startTime,
  endTime,
}: {
  patientId: PatientId;
  startTime: string;
  endTime: string;
}): Promise<APIResponse<GenerateAVSPayload>> => {
  return await api.post(`v2/chats/summarize`, {
    patientId,
    startTime,
    endTime,
  });
};

export const saveAVS = async ({
  encounterId,
  content,
}: {
  encounterId: PrimaryKey;
  content: string;
}): Promise<APIResponse<SaveAVSPayload>> => {
  return await api.put(`v2/encounter/${encounterId}/after-visit-summary`, {
    content,
  });
};

export const getCopilotSuggestion = async (
  patientId: PatientId,
): Promise<APIResponse<SuggestionPayload>> => {
  return await api.get(`v2/patient/${patientId}/ai-chat-suggestion`);
};

export const getAllCarepods = async (): Promise<APIResponse<Carepod[]>> =>
  await api.get(`v2/care-pods`);

export const getSponsorDivisions = async (
  sponsorId: string,
): Promise<APIResponse<GetSponsorDivisionsPayload>> =>
  await api.get(`v2/sponsor/${sponsorId}/divisions`);
